import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "annual_cash_flow_table",
    "quarterly_cash_flow_table",
    "heading",
    "annual_button",
    "quarterly_button",
  ];

  connect() {}

  annualButton(event) {
    event.preventDefault();
    if (this.annual_cash_flow_tableTarget.classList.contains("hidden")) {
      this.toggleCashFlowTables("Annual Cash Flows");
      this.toggleButtonClasses(this.annual_buttonTarget);
      this.toggleButtonClasses(this.quarterly_buttonTarget);
    }
  }

  quarterlyButton(event) {
    event.preventDefault();
    if (this.quarterly_cash_flow_tableTarget.classList.contains("hidden")) {
      this.toggleCashFlowTables("Quarterly Cash Flows");
      this.toggleButtonClasses(this.annual_buttonTarget);
      this.toggleButtonClasses(this.quarterly_buttonTarget);
    }
  }

  toggleCashFlowTables(headingText) {
    this.annual_cash_flow_tableTarget.classList.toggle("hidden");
    this.quarterly_cash_flow_tableTarget.classList.toggle("hidden");
    this.headingTarget.textContent = headingText;
  }

  toggleButtonClasses(button) {
    const classesToToggle = [
      "bg-gray-100",
      "hover:bg-gray-200",
      "bg-green-600",
      "hover:bg-green-700",
      "text-neutral-900",
      "text-neutral-50",
      "focus:ring-gray-500",
      "focus:border-gray-500",
      "focus:ring-green-500",
      "focus:border-green-500",
    ];

    classesToToggle.forEach((cls) => {
      button.classList.toggle(cls, !button.classList.contains(cls));
    });
  }
}


