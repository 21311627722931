import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';


export default class extends Controller {

  static values = {
    inputs: Object
  }

  static targets = [
    "total_debt_table_heading",
    "annual_total_debt_table",
    "quarterly_total_debt_table",
    "annual_total_debt_table_toggle_button",
    "quarterly_total_debt_table_toggle_button",
  ];

  connect() {

    const chartData = JSON.parse(this.element.getAttribute('data-show-stock-total-debt-page-inputs-value'));

    Highcharts.chart('stockShowTotalDebtChartContainer', {
      chart: {
        type: 'area',
        zooming: {
          type: 'x'
        },
        panning: true,
        panKey: 'shift',
        scrollablePlotArea: {
          minWidth: 600
        }
      },
    
      title: {
        text: '',
        align: 'left'
      },
    
      credits: {
        enabled: false
      },
    
      // Removed annotations to simplify the chart
      xAxis: {
        labels: {
          format: '{value}'
        },
        minRange: 1,
        title: {
          text: 'Years'
        },
      },
    
      yAxis: {
        startOnTick: true,
        endOnTick: false,
        maxPadding: 0.35,
        title: {
          text: 'TotalDebt'
        },
        labels: {
          format: `{value} ${chartData.reported_currency}`
        },
      },
    
      tooltip: {
        headerFormat: 'Year: {point.x}<br>', // Removed decimal places from the year
        pointFormat: `TotalDebt: {point.y} ${chartData.reported_currency}`,
        shared: true
      },
    
      legend: {
        enabled: false
      },
    
      series: [{
        data: chartData.annual_data, // Assuming `chartData.annual_data` is an array of [year, total_debt]
        lineColor: Highcharts.getOptions().colors[1],
        color: Highcharts.getOptions().colors[4],
        fillOpacity: 0.8,
        name: 'TotalDebt',
        marker: {
          enabled: false
        },
        threshold: null
      }],
    
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500 // Adjust for smaller screens
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            yAxis: {
              labels: {
                format: `{value} ${chartData.reported_currency}`
              }
            },
            tooltip: {
              headerFormat: 'Year: {point.x}<br>',
              pointFormat: `TotalDebt: {point.y} ${chartData.reported_currency}`
            }
          }
        }]
      }
    });
  }

  annualTotalDebtTableToggle(event) {
    event.preventDefault();
    if (this.annual_total_debt_tableTarget.classList.contains("hidden")) {
      this.toggleCardsAndHeading("Annual");
      this.toggleButtonClasses(this.annual_total_debt_table_toggle_buttonTarget);
      this.toggleButtonClasses(this.quarterly_total_debt_table_toggle_buttonTarget);
    }
  }

  quarterlyTotalDebtTableToggle(event) {
    event.preventDefault();
    if (this.quarterly_total_debt_tableTarget.classList.contains("hidden")) {
      this.toggleCardsAndHeading("Quarterly");
      this.toggleButtonClasses(this.annual_total_debt_table_toggle_buttonTarget);
      this.toggleButtonClasses(this.quarterly_total_debt_table_toggle_buttonTarget);
    }
  }

  toggleCardsAndHeading(headingText) {
    this.annual_total_debt_tableTarget.classList.toggle("hidden");
    this.quarterly_total_debt_tableTarget.classList.toggle("hidden");
    this.total_debt_table_headingTarget.textContent = headingText;
  }

  toggleButtonClasses(button) {
    const classesToToggle = [
      "bg-gray-100",
      "hover:bg-gray-200",
      "bg-green-500",
      "hover:bg-green-700",
      "text-neutral-900",
      "text-neutral-50",
      "focus:ring-gray-500",
      "focus:border-gray-500",
      "focus:ring-green-500",
      "focus:border-green-500",
    ];

    classesToToggle.forEach((cls) => {
      button.classList.toggle(cls, !button.classList.contains(cls));
    });
  }
}

