// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import { createChart } from 'lightweight-charts';

document.addEventListener('turbo:load', () => {
  const chartContainer = document.getElementById('chart-container');
  if (chartContainer) {
    const chartData = JSON.parse(chartContainer.dataset.chartData);
    const chart = createChart(chartContainer, { width: chartContainer.clientWidth, height: 400 });

    chart.applyOptions({
      handleScroll: {
          mouseWheel: false, // Allows scrolling with mouse wheel
          pressedMouseMove: false // Enables scrolling when dragging
      },
      handleScale: {
          axisPressedMouseMove: false, // Enables scaling with drag
          mouseWheel: false // Enables scaling with mouse wheel
      }
  });

    

  const lineSeries = chart.addAreaSeries({
    topColor: 'rgba(25, 118, 210, 0.35)',
    bottomColor: 'rgba(25, 118, 210, 0.15)',
    lineColor: 'rgba(25, 118, 210, 1)',
    lineWidth: 2,
    crossHairMarkerVisible: false,
  });
    lineSeries.setData(chartData);

    window.addEventListener('resize', () => {
      chart.resize(chartContainer.clientWidth, 400);
    });
  }
});
